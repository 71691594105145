import Api from "../../Api";

const END_POINT_ALLFAMILIA = 'user/finished-product/finished-product-families/all';
const END_POINT_CREATEFAMILIA = 'user/finished-product/finished-product-families/create';
const END_POINT_UPDATEFAMILIA = 'user/finished-product/finished-product-families/update';
const END_POINT_DELETEFAMILIA = 'user/finished-product/finished-product-families/delete';

export default {

  GetAllFamilias(data){
    return Api.get(END_POINT_ALLFAMILIA, {
      headers: {
          'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  PostCreateFamilia(data, auth) {
    return Api.post(END_POINT_CREATEFAMILIA, {
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateFamilia(data, auth) {
    return Api.patch(END_POINT_UPDATEFAMILIA, {
      'id': data.id,
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteByFamilia(id, auth) {
    return Api.delete(END_POINT_DELETEFAMILIA + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }

}